import { useState, useEffect } from "react"
import { FaSave } from "react-icons/fa"
import { getAuth, updateProfile, updatePassword, onAuthStateChanged } from "firebase/auth"
import { useNavigate } from "react-router-dom"
import ReactPasswordChecklist from "react-password-checklist"
import { useStateContext } from "../Contexts/ContextProvider"
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { Bounce, toast, ToastContainer } from "react-toastify"

export default function Settings() {
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem('user') || '{}'))
  const [name, setName] = useState(userData.user?.username || '')
  const [email, setEmail] = useState(userData.user?.email || '')
  const [traderId, setTraderId] = useState(userData.user?.traderId || '')
  const [password, setPassword] = useState(userData.user?.password || '')
  const [currentUser, setCurrentUser] = useState(null)
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate()
  const auth = getAuth()
  const [validPass, setValidPass] = useState();
  const {
    setActiveMenu,
    setRole,
    setIsAuthenticated
  } = useStateContext();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user)
        console.log('User :', user)
      } else {
        navigate('/login')
      }
    })
    return () => unsubscribe()
  }, [auth, navigate])

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!currentUser) {
      alert("You must be logged in to update your profile.");
      return;
    }

    // Prepare request body for the backend update
    const updatedUser = {};
    if (name !== userData.user?.username) {
      updatedUser.username = name;
    }
    if (password) {
      updatedUser.password = password;
    }

    try {
      if (name !== userData.user?.username) {
        await updateProfile(currentUser, {
          displayName: name,
        });
      }
      if (password) {
        await updatePassword(currentUser, password);
      }

      const response = await fetch(
        `https://webai-forex-backend-ashy.vercel.app/api/auth/update-user/${userData.user?._id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updatedUser),
        }
      );

      const data = await response.json();

      if (response.ok) {
        // alert("Your details were updated successfully. Please log in again.");
        toast('Your details were updated successfully. Please log in again.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
          transition: Bounce,
        });
        await auth.signOut();
        localStorage.removeItem('user');
        localStorage.removeItem("isAuthenticated");
        localStorage.removeItem("role");
        setIsAuthenticated(false);
        setRole("");
        setActiveMenu(true);
      } else {
        // alert(data.message || "An error occurred while updating your details.");
        toast(data.message || "An error occurred while updating your details", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
          transition: Bounce,
        });
      }
    } catch (error) {
      console.error("Error updating user details:", error);

      if (error.code === "auth/requires-recent-login") {
        // alert("You need to reauthenticate to update your profile. Please log in again and try.")
        toast("You need to reauthenticate to update your profile. Please log in again and try.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
          transition: Bounce,
        });
        await auth.signOut();
        localStorage.removeItem('user');
        localStorage.removeItem("isAuthenticated");
        localStorage.removeItem("role");
        setIsAuthenticated(false);
        setRole("");
        setActiveMenu(true);
      } else {
        // alert("Something went wrong. Please try again later.");
        toast("Something went wrong. Please try again later.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
          transition: Bounce,
        });
      }
    }
  };


  if (!currentUser) {
    return <div>Loading...</div>
  }

  return (
    <>
      <div className='min-h-[91vh] relative' style={{ backgroundImage: 'url(bg.svg)' }}>
        <div className="p-4 md:p-6 mt-20 md:mt-0">
          <ToastContainer />
          <h1 className='text-[#22093A] text-[24px] leading-[24px] font-bold mb-7'>GENERAL SETTING</h1>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm md:text-[19.03px] leading-[1.2] text-[#8A8A8A] font-medium mb-2"
                >
                  Name
                </label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Enter name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="w-full outline-none border border-gray-300 px-3 py-2 md:py-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm md:text-[19.03px] leading-[1.2] text-[#8A8A8A] font-medium mb-2"
                >
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  disabled={true}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full outline-none border border-gray-300 px-3 py-2 md:py-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div>
                <label
                  htmlFor="traderId"
                  className="block text-sm md:text-[19.03px] leading-[1.2] text-[#8A8A8A] font-medium mb-2"
                >
                  Trader-ID
                </label>
                <input
                  id="traderId"
                  name="traderId"
                  type="text"
                  placeholder="Enter trader ID"
                  value={traderId}
                  onChange={(e) => setTraderId(e.target.value)}
                  disabled={true}
                  className="w-full outline-none border border-gray-300 px-3 py-2 md:py-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm md:text-[19.03px] leading-[1.2] text-[#8A8A8A] font-medium mb-2"
                >
                  Password
                </label>
                <div className="relative">
                  <input
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter new password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full outline-none border border-gray-300 px-3 py-2 md:py-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <span
                    className="absolute inset-y-0 right-3 flex items-center cursor-pointer text-gray-500"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <FiEye size={18} /> : <FiEyeOff size={18} />}
                  </span>
                </div>
                <ReactPasswordChecklist
                  rules={["minLength", "specialChar", "number", "capital"]}
                  minLength={8}
                  value={password}
                  messages={{
                    minLength: "The password is more than 8 characters.",
                    specialChar: "The password has special characters.",
                    number: "The password has a number.",
                    capital: "The password has a capital letter.",
                  }}
                  onChange={(isValid) => setValidPass(isValid)}
                />
              </div>
            </div>
            <div className="flex space-x-4">
              {
                validPass ?
                  <button
                    type="submit"
                    className="bg-[#3EBA59] text-white rounded-md px-4 md:px-[19.03px] py-3 md:py-[16.31px] text-sm md:text-[19.03px] leading-[1.2] transition-transform duration-200 ease-in-out transform hover:scale-90 hover:bg-green-500 hover:shadow-lg hover:font-semibold flex items-center justify-center"
                  >
                    <FaSave className="mr-2" />
                    Save
                  </button> :
                  <button
                    type="submit"
                    className="bg-[#b2bec3] text-white rounded-md px-4 md:px-[19.03px] py-3 md:py-[16.31px] text-sm md:text-[19.03px] leading-[1.2] transition-transform duration-200 ease-in-out transform hover:scale-90 hover:shadow-lg hover:font-semibold flex items-center justify-center"
                    disabled={true}
                  >
                    <FaSave className="mr-2" />
                    Save
                  </button>
              }
            </div>
          </form>
        </div>
      </div>
    </>
  )
}