import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal } from "react-responsive-modal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-responsive-modal/styles.css";
import Slider from "react-slick";
import NotificationIcon from '../Assets/NotificationsIcon.svg';

const Notifications = () => {
    const [analysisData, setAnalysisData] = useState([]);
    const [error, setError] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAddAnalysisModalOpen, setIsAddAnalysisModalOpen] = useState(false);

    const fetchAnalysisData = async () => {
        try {
            const response = await axios.get(
                `https://webai-forex-backend-ashy.vercel.app/api/notifications`
            );
            console.log('Notificaiotbs: ', response.data.notifications)
            setAnalysisData(response.data.notifications);
        } catch (error) {
            console.error("Error fetching analysis data:", error);
            setError("Failed to fetch analysis data");
        }
    };

    useEffect(() => {
        fetchAnalysisData();
    }, []);

    const handleReadMore = (item) => {
        setSelectedItem(item);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedItem(null);
    };

    const openAddAnalysisModal = () => {
        setIsAddAnalysisModalOpen(true);
        setSelectedItem(null);
    };

    const closeAddAnalysisModal = () => {
        setIsAddAnalysisModalOpen(false);
    };

    const handleEdit = (item) => {
        setSelectedItem(item);
        setIsAddAnalysisModalOpen(true);
    };

    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you want to delete this analysis?")) {
            try {
                const response = await axios.delete(
                    `${process.env.REACT_APP_BASE_URL}/api/notifications/${id}`
                );
                alert(response.data.message);
                fetchAnalysisData();
            } catch (error) {
                console.error("Error deleting analysis:", error);
                setError("Failed to delete analysis");
            }
        }
    };

    if (error) {
        return <div className="text-red-600">Error: {error}</div>;
    }

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
    };

    return (
        <>
            <div className='min-h-[91vh] relative' style={{ backgroundImage: 'url(bg.svg)' }}>
                <div className="p-7 md:px-8 md:mt-0 mt-16 mb-16 lg:mb-0">
                    <h1 className='text-[#22093A] text-[24px] leading-[24px] font-bold mb-7'>NOTIFICATIONS</h1>
                    <div className="grid grid-cols-1 gap-4">
                        {analysisData.map((item) => (
                            <div
                                key={item._id}
                                className="bg-[#EBEBEB] p-2 rounded-lg shadow flex flex-wrap md:flex-nowrap gap-4 hover:shadow-md transition-shadow"
                            >
                                <img
                                    src={item.image}
                                    alt={'Notification img'}
                                    className="w-full md:max-w-[160px] h-40 object-cover rounded-md"
                                />
                                <div>
                                    <p className="text-sm text-gray-600 font-display mb-6">
                                        {item.message}
                                    </p>
                                </div>
                            </div>

                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Notifications;