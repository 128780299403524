import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Navbar, Sidebar } from "./Components";
import "./App.css";
import { useStateContext } from "./Contexts/ContextProvider";
import Courses from "./Pages/Courses";
import FundedAccount from "./Pages/FundedAccount";
import Analysis from "./Pages/Analysis";
import MembersChat from "./Pages/MembersChat";
import DepartmentDashboard from "./Pages/Department";
import SubAdmin from "./Pages/SubAdmin";
import SuspendedAccount from "./Pages/SuspendedAccount";
import Verification from "./Pages/Verification";
import Login from "./Pages/Login";
import SignUp from "./Pages/SignUp";
import '@progress/kendo-theme-default/dist/all.css';
import Support from "./Pages/Support";
import Settings from "./Pages/Settings";
import Notifications from "./Pages/Notifications";
import AccUnderVerfication from "./Pages/AccUnderVerification";

const App = () => {
  const {
    setCurrentColor,
    setCurrentMode,
    currentMode,
    activeMenu,
    setActiveMenu,
    role,
    setRole,
    isAuthenticated,
    setIsAuthenticated
  } = useStateContext();


  // Hardcoded users
  const users = [
    { username: "user1@gmail.com", password: "user1pass", role: "user" },
    { username: "user2@gmail.com", password: "user2pass", role: "user" },
  ];

  useEffect(() => {
    const currentThemeColor = localStorage.getItem("colorMode");
    const currentThemeMode = localStorage.getItem("themeMode");
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }

    // Check if the user is logged in
    const loggedIn = localStorage.getItem("isAuthenticated");
    const userRole = localStorage.getItem("role");
    if (loggedIn) {
      setIsAuthenticated(true);
      setRole(userRole);
    }
  }, []);

  const handleLogin = (email, traderId) => {
    // Check if user exists
    const user = users.find((u) => u.username === email && u.password === traderId);
    if (user) {
      setRole(user.role);
      localStorage.setItem("isAuthenticated", "true");
      localStorage.setItem("role", user.role);
      setIsAuthenticated(true);
      setActiveMenu(false); // Close menu on login
    } else {
      alert("Invalid credentials");
    }
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    setRole("");
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("role");
    setActiveMenu(true); // Reset active menu on logout
  };

  return (
    <div className={currentMode === "Dark" ? "dark" : ""}>
      <BrowserRouter>
        {/* Main Layout */}
        {isAuthenticated && (
          <div className="flex relative dark:bg-main-dark-bg">
            {activeMenu && (
              <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white">
                <Sidebar handleLogout={handleLogout} />
              </div>
            )}
            <div
              className={
                activeMenu
                  ? "dark:bg-main-dark-bg bg-main-bg min-h-screen md:ml-72 w-full"
                  : "bg-main-bg dark:bg-main-dark-bg w-full min-h-screen flex-2"
              }
            >
              <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full">
                <Navbar />
              </div>
              {/* Authenticated Routes */}
              <Routes>
                <>
                  <Route path="/membersChat" element={<MembersChat />} />
                  <Route path="/courses" element={<Courses />} />
                  {/* <Route path="/fundedaccount" element={<FundedAccount />} /> */}
                  <Route path="/analysis" element={<Analysis />} />
                  <Route path="/support" element={<Support />} />
                  <Route path="/notifications" element={<Notifications />} />
                  <Route path="/settings" element={<Settings />} />
                  <Route path="/accountUnderVerification" element={<AccUnderVerfication />} />
                  <Route path="*" element={<Navigate to="/membersChat" />} />
                </>
              </Routes>
            </div>
          </div>
        )}

        {/* Unauthenticated Routes */}
        {!isAuthenticated && (
          <Routes>
            <Route path="/signup" element={<SignUp />} />
            <Route path="/login" element={<Login onLogin={handleLogin} />} />
            <Route path="*" element={<Navigate to="/login" />} />
            <Route path="/accountUnderVerification" element={<AccUnderVerfication />} />
          </Routes>
        )}
      </BrowserRouter>
    </div>
  );
};

export default App;
