// import React from "react";
// import { analysisTopCards, analysisBottomCards } from "../Data/dummy.js";

// const Analysis = () => {
//   return (
//     <div className="bg-gray-50 p-5 md:px-8 md:mt-4 sm: mt-20">
//       {/* Lower Section - Horizontal Cards */}
//       <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//         {analysisBottomCards.map((i, index) => (
//           <div
//             key={i.id}
//             className="bg-[#EBEBEB] p-5 rounded-lg shadow flex flex-wrap md:flex-nowrap gap-4 hover:shadow-md transition-shadow"
//           >
//             <img
//               src={i.img}
//               alt="Horizontal Image"
//               className="w-full md:max-w-[256px] h-44 object-cover rounded-md"
//             />
//             <div>
//               <h3 className="text-sm font-display font-semibold text-gray-800 mb-2">
//                 {i.title}
//               </h3>
//               <p className="text-sm text-gray-600 font-display mb-3">
//                 {i.subTitle}
//               </p>
//               <a href="/" className="text-purple-600 font-display text-sm">
//                 {i.description}
//               </a>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Analysis;

// import React, { useEffect, useState } from "react";
// import axios from "axios";

// const Analysis = () => {
//   const [analysisData, setAnalysisData] = useState([]);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchAnalysisData = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_BASE_URL}/api/analysis`
//         );

//         // Check if response data has the expected structure
//         if (response.data && Array.isArray(response.data.analysis)) {
//           setAnalysisData(response.data.analysis);
//         } else {
//           setAnalysisData([]); // Set to empty array if data is not as expected
//           console.log(response.data)
//           setError("Unexpected data format received");
//         }
//       } catch (error) {
//         console.error("Error fetching analysis data:", error);
//         setError("Failed to fetch analysis data");
//       }
//     };

//     fetchAnalysisData();
//   }, []);

//   if (error) {
//     return <div className="text-red-600">Error: {error}</div>;
//   }

//   return (
//     <div className="bg-gray-50 p-5 md:px-8 md:mt-4 sm:mt-20">
//       <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//         {analysisData.map((item) => (
//           <div
//             key={item._id}
//             className="bg-[#EBEBEB] p-5 rounded-lg shadow flex flex-wrap md:flex-nowrap gap-4 hover:shadow-md transition-shadow"
//           >
//             <img
//               src={item.image}
//               alt={item.title}
//               className="w-full md:max-w-[256px] h-44 object-cover rounded-md"
//             />
//             <div>
//               <h3 className="text-sm font-display font-semibold text-gray-800 mb-2">
//                 {item.title}
//               </h3>
//               <p className="text-sm text-gray-600 font-display mb-3">
//                 {item.description}
//               </p>
//               <a href="/" className="text-purple-600 font-display text-sm">
//                 Read More
//               </a>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Analysis;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal } from "react-responsive-modal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-responsive-modal/styles.css";
import Slider from "react-slick";

const Analysis = () => {
  const [analysisData, setAnalysisData] = useState([]);
  const [error, setError] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchAnalysisData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/analysis`
        );
        if (Array.isArray(response.data)) {
          setAnalysisData(response.data);
        } else {
          setAnalysisData([]);
          setError("Unexpected data format received");
        }
      } catch (error) {
        console.error("Error fetching analysis data:", error);
        setError("Failed to fetch analysis data");
      }
    };

    fetchAnalysisData();
  }, []);

  const handleReadMore = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
  };

  if (error) {
    return <div className="text-red-600">Error: {error}</div>;
  }

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
  };

  return (
    <>
    <div className='min-h-[91vh] relative' style={{ backgroundImage: 'url(bg.svg)'}}>
    <div className=" p-5 md:px-8 md:mt-4 mt-16 mb-16 lg:mb-0 w-full absolute">
      <h1 className='text-[#22093A] text-[24px] leading-[24px] font-bold mb-7'>ANALYSIS</h1>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        {analysisData.map((item) => (
          <div
            key={item._id}
            className="bg-[#EBEBEB] p-5 rounded-lg shadow flex flex-wrap md:flex-nowrap gap-4 hover:shadow-md transition-shadow"
          >
            <img
              src={item.images[0]}
              alt={item.title}
              className="w-full md:max-w-[216px] h-44 object-cover rounded-md"
            />
            <div>
              <h3 className="text-base font-display font-semibold text-gray-800 mb-3">
                {item.title}
              </h3>
              <p className="text-sm text-gray-600 font-display mb-6">
                {item.description.slice(0, 85)}...
              </p>
              <button
                onClick={() => handleReadMore(item)}
                className="text-purple-600 font-display text-sm transition duration-200 ease-in-out transform hover:text-purple-800 hover:scale-105 hover:underline"
              >
                Read More
              </button>
            </div>
          </div>
        ))}
      </div>
      {selectedItem && (
        <Modal open={isModalOpen} onClose={closeModal} center>
          <div className="p-5 md:p-6 lg:p-8 max-w-xs sm:max-w-md md:max-w-lg lg:max-w-2xl mx-auto bg-white rounded-xl overflow-hidden">
            <Slider {...sliderSettings} className="mt-2">
              {selectedItem.images.map((img, index) => (
                <div key={index}>
                  <img
                    src={img}
                    alt={`Slide ${index + 1}`}
                    className="w-full h-48 sm:h-56 md:h-64 object-cover rounded-md"
                  />
                </div>
              ))}
            </Slider>
            <h2 className="text-base sm:text-lg md:text-xl font-semibold mt-6">{selectedItem.title}</h2>
            <p
              className="text-gray-700 mt-4 max-h-32 overflow-y-auto pr-2 text-sm sm:text-base"
              style={{
                scrollbarWidth: "none",
                msOverflowStyle: "none",
              }}
            >
              {selectedItem.description}
            </p>
          </div>
        </Modal>
      )}
    </div>
    </div>
    </>
  );
};

export default Analysis;