// import React, { useEffect, useState, useRef } from 'react';
// import Background from '../Assets/Background.svg';
// import LoginBg from '../Assets/LoginBg.svg';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import { ToastContainer, toast, Bounce } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
// import { FiEye, FiEyeOff } from 'react-icons/fi';


// const auth = getAuth();
// const AccUnderVerfication = ({ isOpen, onClose }) => {
//     const [email, setEmail] = useState('');
//     const [name, setName] = useState('');
//     const [traderId, setTraderId] = useState('');
//     // const [otp, setOTP] = useState('');
//     const [userId, setUserId] = useState('');
//     const [showOtpInput, setShowOtpInput] = useState(false);
//     const [password, setPassword] = useState('');
//     const [showPassword, setShowPassword] = useState(false);
//     const navigate = useNavigate();
//     const endPoint = 'https://webai-forex-backend-ashy.vercel.app'
//     const [otp, setOtp] = useState(new Array(6).fill(''));
//     const inputRefs = useRef([]);

//     const togglePasswordVisibility = () => {
//         setShowPassword(!showPassword);
//     };


//     const handleChange = (e, idx) => {
//         const value = e.target.value;
//         if (/^[0-9]?$/.test(value)) {  // Only allow a single digit
//             const newOtp = [...otp];
//             newOtp[idx] = value;
//             setOtp(newOtp);

//             // Move to the next input if the current one is filled
//             if (value && idx < otp.length - 1) {
//                 inputRefs.current[idx + 1].focus();
//             }
//         }
//     };

//     const handleKeyDown = (e, idx) => {
//         if (e.key === 'Backspace' && !otp[idx] && idx > 0) {
//             inputRefs.current[idx - 1].focus();
//         }
//     };


//     const backgroundStyle = {
//         height: '100vh',
//         backgroundImage: `url(${Background})`,
//         backgroundSize: 'cover',
//         backgroundRepeat: 'no-repeat',
//         backgroundPosition: 'center',
//     };

//     // Firebase Signup and API call for OTP verification
//     const handleFirebaseSignup = async () => {
//         try {
//             // Create a new user in Firebase Authentication
//             await createUserWithEmailAndPassword(auth, email, password);

//             // Make API call to register user details and get userId for OTP verification
//             const response = await axios.post(`${endPoint}/api/auth/signup`, {
//                 email,
//                 username: name,
//                 traderId,
//                 password,
//                 mobileNumber: "1234567890"
//             });
//             setUserId(response.data.userId);
//             console.log('Upcoming user id: ', response.data.userId);
//             console.log('Response: ', response)
//             setShowOtpInput(true);
//             toast.success(response.data.message, { position: "top-right", autoClose: 5000, theme: "light", transition: Bounce });
//         } catch (error) {
//             toast.error('Signup failed. Please try again.', { position: "top-right", autoClose: 5000, theme: "light", transition: Bounce });
//             console.error(error);
//         }
//     };

//     const handleOtpVerification = async () => {
//         try {
//             const response = await axios.post(`${endPoint}/api/auth/verify-otp`, {
//                 userId: userId,
//                 otp: otp.join('') // Convert the OTP array to a string
//             });
//             toast.success(response.data.message, { position: "top-right", autoClose: 5000, theme: "light", transition: Bounce });
//             navigate('/login');
//         } catch (error) {
//             toast.error('OTP verification failed. Please try again.', { position: "top-right", autoClose: 5000, theme: "light", transition: Bounce });
//             console.error(error);
//         }
//     };

//     useEffect(() => {
//         // alert('Api endpoint', `${endPoint}/api/auth/verify-otp`)
//         console.log('User id: ', userId)
//     }, [])

//     return (
//         <div style={backgroundStyle}>
//             <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick pauseOnFocusLoss draggable pauseOnHover theme="light" transition={Bounce} />
//             <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
//                 <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50" onClick={() => navigate('/')}>
//                     <div className="relative w-[90%] max-w-[900px]  max-h-[650px] bg-[#FAFBFC] rounded-lg p-8 shadow-2xl ju" onClick={(e) => e.stopPropagation()}>
//                         <div style={{
//                             width: '5rem',
//                             height: '5rem',
//                             backgroundColor: '#e6f2ff',
//                             borderRadius: '0.75rem',
//                             display: 'flex',
//                             alignItems: 'center',
//                             justifyContent: 'center',
//                             marginBottom: '1.5rem'
//                         }}>
//                             <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="#3b82f6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
//                                 <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
//                                 <polyline points="22,6 12,13 2,6"></polyline>
//                             </svg>
//                         </div>

//                         <h1 style={{
//                             fontSize: '1.875rem',
//                             fontWeight: 'bold',
//                             color: '#3b82f6',
//                             marginBottom: '1rem'
//                         }}>
//                             Account Under Verification
//                         </h1>

//                         <p style={{
//                             color: '#4b5563',
//                             fontSize: '1.125rem',
//                             maxWidth: '24rem',
//                             marginBottom: '1.5rem'
//                         }}>
//                             We're reviewing your account. You'll receive a notification as soon as verification is complete
//                         </p>

//                         <button
//                             style={{
//                                 backgroundColor: '#dc2626',
//                                 color: 'white',
//                                 padding: '0.75rem 1.5rem',
//                                 borderRadius: '0.375rem',
//                                 fontSize: '1rem',
//                                 fontWeight: 'bold',
//                                 border: 'none',
//                                 cursor: 'pointer',
//                                 width: '100%',
//                                 maxWidth: '24rem'
//                             }}
//                             onClick={() => {/* Add your go back logic here */ }}
//                         >
//                             Okay, Go back
//                         </button>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default AccUnderVerfication;
// import React, { useEffect, useState, useRef } from 'react';
// import Background from '../Assets/Background.svg';
// import { useNavigate } from 'react-router-dom';
// import { ToastContainer, toast, Bounce } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// const AccUnderVerfication = () => {
//     const [userId, setUserId] = useState('');
//     const navigate = useNavigate();


//     const backgroundStyle = {
//         height: '100vh',
//         backgroundImage: `url(${Background})`,
//         backgroundSize: 'cover',
//         backgroundRepeat: 'no-repeat',
//         backgroundPosition: 'center',
//     };
//     useEffect(() => {
//         // alert('Api endpoint', `${endPoint}/api/auth/verify-otp`)
//         console.log('User id: ', userId)
//     }, [])

//     return (
//         <div style={backgroundStyle}>
//             <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick pauseOnFocusLoss draggable pauseOnHover theme="light" transition={Bounce} />
//             <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50" onClick={() => navigate('/')}>
//                 <div className="relative w-[90%] max-w-[900px]  max-h-[650px] bg-[#FAFBFC] rounded-lg p-8 shadow-2xl" onClick={(e) => e.stopPropagation()}>

//                 </div>
//             </div>
//         </div>
//     );
// };

// export default AccUnderVerfication;
import React, { useEffect } from 'react';
import Background from '../Assets/Background.svg';
import acc_under_verification from '../Assets/acc_under_verification.png';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AccUnderVerification = () => {
    const navigate = useNavigate();

    const backgroundStyle = {
        height: '100vh',
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    };

    useEffect(() => {
        console.log('Page loaded');
    }, []);

    return (
        <div style={backgroundStyle} className="flex items-center justify-center">
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Bounce}
            />
            <div className="relative w-[90%] max-w-[900px] h-[500px] bg-[#FAFBFC] rounded-lg shadow-2xl text-center flex flex-col items-center justify-center">
                <img
                    src={acc_under_verification} // Replace with your envelope image path
                    alt="Verification Icon"
                    className="w-40 mb-6"
                />
                <h2 className="text-4xl font-semibold text-[#006FCF] mb-4">
                    Account Under Verification
                </h2>
                <p className="text-gray-700 mb-6 text-xl w-[50%]">
                    We’re reviewing your account. You’ll receive a notification as soon as verification is complete.
                </p>
                <button
                    onClick={() => navigate('/')}
                    className="px-6 py-3 bg-red-600 text-white rounded-md shadow-md hover:bg-red-700 w-[35%] my-4"
                >
                    Okay, Go back
                </button>
            </div>

        </div>
    );
};

export default AccUnderVerification;

