// import styles from '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
// import {
//   MainContainer, ChatContainer, MessageList, Message, MessageInput,
//   ConversationHeader, Avatar, TypingIndicator, MessageSeparator
// } from '@chatscope/chat-ui-kit-react';
// import { useStateContext } from '../Contexts/ContextProvider';
// import CharacterImg from '../Assets/character_avtar.png';
// import Logo from '../Assets/Logo.svg';
// import Avtar from '../Assets/Avtar.svg';
// import { useState, useEffect } from 'react';
// import axios from 'axios';
// import './Chatcontainer.css';

// const users = [
//   { id: 1, name: "Alice Johnson" },
//   { id: 2, name: "Bob Smith" },
//   { id: 3, name: "Charlie Brown" },
//   { id: 4, name: "Diana Prince" },
//   { id: 5, name: "Evan Wright" },
// ];

// export default function MemberChat() {
//   const [currentSlide, setCurrentSlide] = useState(0);
//   const [selectedUser, setSelectedUser] = useState(users[0]);
//   const { role } = useStateContext();
//   const date = new Date();
//   const [messages, setMessages] = useState([]);

//   // Fetch messages from the API by groupId
//   const fetchMessages = async () => {
//     try {
//       const response = await axios.get('https://webai-forex-backend-ashy.vercel.app/api/chat/group/67290e562faba7bce90089d9');
//       console.log("Repsonse data", response.data.messages)
//       const chatMessages = response.data.messages.map(msg => {
//         const isImage = msg.message && (msg.message.startsWith('https://firebasestorage.googleapis.com') || /\.(jpg|jpeg|png|gif|bmp|svg)$/i.test(msg.message));

//         return {
//           type: isImage ? 'image' : 'text',
//           direction: 'incoming',
//           payload: msg.message || '', // Default to an empty string if message is null
//           sender: 'Admin', // Adjust as per role or API response if needed
//           sentTime: msg.timestamp ? new Date(msg.timestamp).toLocaleTimeString() : '', // Handle cases where timestamp might be null
//         };
//       });
//       console.log('Api Messages: ', chatMessages)
//       setMessages(chatMessages);
//     } catch (error) {
//       console.error('Error fetching messages:', error);
//     }
//   };

//   // Fetch messages on mount and set up polling every 5 seconds
//   useEffect(() => {
//     fetchMessages();
//     const interval = setInterval(fetchMessages, 5000); // Poll every 5 seconds
//     return () => clearInterval(interval); // Cleanup interval on unmount
//   }, []);

//   const handleSend = async (message) => {
//     if (message) {
//       const newMessage = {
//         direction: role === 'admin' ? 'outgoing' : 'incoming',
//         message,
//         position: 'single',
//         sender: role === 'admin' ? 'Admin' : 'user',
//         sentTime: 'Just now',
//       };
//       setMessages((prevMessages) => [...prevMessages, newMessage]);

//       // Post the message to the API (if required)
//       try {
//         await axios.post(`https://webai-forex-backend-ashy.vercel.app/api/chat/group/67212839f1218a66ce3fbc5d`, { message });
//       } catch (error) {
//         console.error('Error sending message:', error);
//       }
//     }
//   };
//   useEffect(() => {
//     window.scrollTo(0, document.body.scrollHeight);
//   }, []);

//   return (
//     <div className="flex bg-gray-100 h-screen font-display">
//       <div className="flex-1">
//         <MainContainer>
//           <ChatContainer
//             className="custom-chat-container"
//             style={{
//               height: '100vh',
//               display: 'flex',
//               flexDirection: 'column',
//               paddingBottom: '10px',
//               overflow: 'hidden'
//             }}
//           >
//             <ConversationHeader>
//               <ConversationHeader.Content
//                 info={role === 'user' ? "Active 10 mins ago" : null}
//                 userName={role === 'admin' ? 'Forexyy-Tradie Members Chat' : 'Admin'}
//               />
//             </ConversationHeader>

//             <MessageList
//               typingIndicator={role === 'user' ? <TypingIndicator content="Admin is typing" /> : null}
//             >
//               <MessageSeparator content={date.toDateString()}
//                 className="custom-chat-container"
//               />
//               {messages.map((msg, index) => (
//                 <Message
//                   key={index}
//                   model={msg}
//                 >
//                   {
//                     msg.type === 'text' ?
//                       (
//                         <Message.TextContent style={{ whiteSpace: 'pre-wrap' }}>{msg.payload}</Message.TextContent>
//                       ) : (
//                         <Message.ImageContent
//                           src={msg.payload}
//                           style={{
//                             width: '100%',
//                             maxWidth: 250,
//                           }}
//                           width={250}
//                         />
//                       )}
//                 </Message>
//               ))}
//             </MessageList>

//             {role === 'admin' && (
//               <MessageInput
//                 className="custom-chat-container"
//                 placeholder="Type message here"
//                 onSend={handleSend}
//               />
//             )}
//           </ChatContainer>
//         </MainContainer>
//       </div>
//     </div>
//   );
// }
import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { HiDotsVertical } from "react-icons/hi";
import { FaAngleLeft } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import { FaImage } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { IoMdSend } from "react-icons/io";
import backgroundImage from '../Assets/wallapaper.jpeg'
import axios from 'axios';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { storage } from './firebaseConfig'
import SearchUser from './SearchUser';

const MemberChat = () => {
  const [openImageVideoUpload, setOpenImageVideoUpload] = useState(false)
  const [participants, setParticipants] = useState([])
  const [messages, setMessages] = useState([])
  const [inputMessage, setInputMessage] = useState()
  const [imageUrl, setImageUrl] = useState(null);
  const [openParticipant, setOpenParticipant] = useState(false);
  const fileInputRef = useRef()
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [messages]);

  const handleUploadImageVideoOpen = () => {
    setOpenImageVideoUpload(preve => !preve)
  }

  const sendMessageToAPI = async (message) => {
    try {
      await axios.post(
        "https://webai-forex-backend-ashy.vercel.app/api/admin/group/67290e562faba7bce90089d9/message",
        {
          adminEmail: "admin123@gmail.com",
          message: message.type === 'text' ? message.payload : message.payload.src
        }
      )
    } catch (error) {
      console.error("Error sending message to API:", error)
    }
  }

  const sanitizeMessage = (message) => {
    if (typeof message !== 'string') return ''
    let sanitized = message.replace(/<[^>]+>/g, '')
    sanitized = sanitized.replace(/&nbsp;/g, ' ')
    sanitized = sanitized.replace(/&lt;/g, '<')
    sanitized = sanitized.replace(/&gt;/g, '>')
    sanitized = sanitized.replace(/&amp;/g, '&')
    sanitized = sanitized.replace(/&quot;/g, '"')
    sanitized = sanitized.replace(/&#39;/g, "'")
    sanitized = sanitized.replace(/\s+/g, ' ')
    sanitized = sanitized.trim()

    return sanitized
  }

  const handleAttachClick = () => {
    fileInputRef.current.click()
  }
  const uploadImage = async (file) => {
    const imageName = `chat-images/${Date.now()}-${file.name}`
    const imageRef = ref(storage, imageName)
    await uploadBytes(imageRef, file)
    return await getDownloadURL(imageRef)
  }
  const handleFileChange = async (event) => {
    const file = event.target.files[0]
    if (file) {
      try {
        const imageUrl = await uploadImage(file)
        const newMessage = {
          direction: 'outgoing',
          type: 'image',
          payload: {
            src: imageUrl,
            alt: file.name,
            width: 300,
            height: 200,
          },
          position: 'single',
          sender: 'Admin',
          sentTime: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }),
          imageName: file.name
        }
        setOpenImageVideoUpload(false)
        setMessages((prevMessages) => [...prevMessages, newMessage])
        await sendMessageToAPI(newMessage)
      } catch (error) {
        console.error("Error uploading image:", error)
      }
    }
  }

  const handleSend = async (e) => {
    e.preventDefault();
    const sanitizedMessage = sanitizeMessage(inputMessage)
    if (!sanitizedMessage && !imageUrl) return;
    const newMessage = {
      direction: 'outgoing',
      type: 'text',
      payload: sanitizedMessage,
      position: 'single',
      sender: 'Admin',
      sentTime: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }),
    }
    setMessages((prevMessages) => [...prevMessages, newMessage])
    await sendMessageToAPI(newMessage)
    setInputMessage('')
    setImageUrl(null);
  }

  const fetchData = async () => {
    try {
      const response = await axios.get("https://webai-forex-backend-ashy.vercel.app/api/chat/group/67290e562faba7bce90089d9")
      const apiMessages = response.data.messages.map(msg => ({
        type: msg.message && (msg.message.startsWith('https://firebasestorage.googleapis.com') || /\.(jpg|jpeg|png|gif|bmp|svg)$/i.test(msg.message)) ? 'image' : 'text',
        direction: 'outgoing',
        payload: msg.message || '',
        position: 'single',
        sender: msg.senderUsername,
        sentTime: new Date(msg.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }),
      }))
      setMessages(apiMessages)
      setParticipants(response.data.participants)
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 5000); // Poll every 5 seconds
    return () => clearInterval(interval); // Cleanup interval on unmount
  });


  return (
    <div style={{ backgroundImage: `url(${backgroundImage})` }} className='bg-no-repeat bg-cover'>
      <header className='sticky top-0 mt-18 h-16 bg-white flex justify-between items-center px-4'>
        <div className='flex items-center gap-4'>
          <Link to={"/"} className='lg:hidden'>
            <FaAngleLeft size={25} />
          </Link>
          <div>
            <h3 className='font-semibold text-lg my-0 text-ellipsis line-clamp-1'>Admin</h3>
          </div>
        </div>
      </header>
      <section className='h-[calc(100vh-128px)] overflow-x-hidden overflow-y-scroll scrollbar relative bg-slate-200 bg-opacity-50'>
        {messages.map((msg, index) => (
          <div className={'flex flex-col gap-2 py-2 mx-2'}>
            <div className='flex flex-col gap-3 w-full max-w-2xl'>
              <div
                key={`message-${index}`}
                className={'p-2 py-1 rounded-lg w-fit max-w-full md:max-w-md lg:max-w-lg bg-white'}
              >
                {msg.type === 'text' ? (
                  <div>
                    <p className='px-3 text-lg'>{msg.payload}</p>
                    <p className='text-xs mt-1 ml-auto w-fit'>{msg.sentTime}</p>
                  </div>
                ) : (
                  <div className='w-full relative max-w-[300px] md:max-w-lg'>
                    <img
                      src={typeof msg.payload === 'string' ? msg.payload : msg.payload.src}
                      className='w-full h-full object-scale-down'
                      alt="sent media"
                    />
                    <p className='text-xs mt-1 ml-auto w-fit'>{msg.sentTime}</p>
                  </div>
                )}
              </div>
              <div ref={messagesEndRef} />
            </div>
          </div>
        ))}
        {imageUrl && (
          <div className='w-full h-full sticky bottom-0 bg-slate-700 bg-opacity-30 flex justify-center items-center rounded overflow-hidden'>
            <div className='w-fit p-2 absolute top-0 right-0 cursor-pointer hover:text-red-600' onClick={handleAttachClick}>
              <IoClose size={30} />
            </div>
            <div className='bg-white p-3'>
              <img
                src={imageUrl}
                alt='upload preview'
                className='aspect-square w-full h-full max-w-sm m-2 object-scale-down'
              />
            </div>
          </div>
        )}
      </section>
      {
        openParticipant && (
          <SearchUser onClose={() => setOpenParticipant(false)} />
        )
      }

    </div>
  )
}

export default MemberChat
