import React, { useState, useEffect } from "react";
import axios from 'axios';
import Filter from '../Assets/Filter.svg';

const Courses = () => {
  const [selectedTab, setSelectedTab] = useState("all");
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/courses`);
        setCourses(response.data);
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };
    fetchCourses();
  }, []);

  return (
    <>
    <div className='min-h-[91vh] relative' style={{ backgroundImage: 'url(bg.svg)'}}>
    <div className="w-full p-4 mt-16 sm:mt-0">
      <h1 className='text-[#22093A] text-[24px] leading-[24px] font-bold mb-7'>COURSES</h1>
      {/* <div className="flex justify-between items-center mb-6">
        <span className="text-lg font-semibold">• TOPICS</span>
        <div className="flex gap-3">
          <button
            className={`px-4 rounded-lg font-semibold ${selectedTab === "all" ? "bg-gray-300" : "bg-white"
              }`}
            onClick={() => setSelectedTab("all")}
          >
            All Courses
          </button>
          <button
            className={`px-4 rounded-lg font-semibold ${selectedTab === "my" ? "bg-gray-300" : "bg-white"
              }`}
            onClick={() => setSelectedTab("my")}
          >
            My Courses
          </button>
          <button className="px-2 py-2 rounded-lg bg-gray-100">
            <img src={Filter} alt="filter" className="w-6 h-6" />
          </button>
        </div>
      </div> */}

      <div className="flex flex-col sm:flex-row justify-center items-center md:items-center md:justify-between md:flex-wrap gap-3 mb-16" style={{ maxWidth: '100%' }}>
        {courses.map((course) => (
          <div key={course._id} className="md:w-[23rem] bg-[#FAFAFA] border px-2 pt-2 pb-6 rounded-lg self-stretch">
            <img
              src={course.image}
              alt={course.title}
              className="rounded-md w-full h-44 object-cover"
            />
            <h2 className="font-semibold text-sm py-4 truncate">
              {course.title}
            </h2>
            <div className="flex items-center justify-between gap-1 pb-8 pt-4">
              <div className="flex items-center gap-2">
                <img src="Lesson.svg" alt="LessonImg" />
                <span className="text-xs font-medium">Lesson : {course.numberOfLessons}</span>
              </div>
              {/* <div className="flex items-center gap-2">
                <img src={course.lessonIcon} alt={course.lesson} />
                <span className="text-xs font-medium">{course.student}</span>
              </div>
              <div className="flex items-center gap-2">
                <img src={course.lessonIcon} alt={course.lesson} />
                <span className="text-xs font-medium">{course.level}</span>
              </div> */}
            </div>
            <div className="flex items-center justify-between">
              <a href={course.link} target="_blank"><button className="text-sm font-medium bg-[#3EBA59] text-white px-4 py-3 rounded-lg transition-transform transform duration-200 ease-in-out hover:scale-105 hover:shadow-lg hover:bg-[#2E9C47] hover:font-semibold">
                Start Course {">"}
              </button></a>
              {/* <div className="text-sm font-medium">
                <img src={course.scoreIcon} alt={course.ratings} />
              </div> */}
            </div>
          </div>
        ))}
      </div>
    </div>
    </div>
    </>
  );
};

export default Courses;