import React, { useEffect, useState, useRef } from 'react';
import Background from '../Assets/Background.svg';
import LoginBg from '../Assets/LoginBg.svg';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import Email from '../Assets/Email.svg';
import TaderId from '../Assets/TraderIdIcon.svg';
import Lock from '../Assets/LockNew.svg'



const auth = getAuth();
const SignUp = ({ isOpen, onClose }) => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [traderId, setTraderId] = useState('');
    // const [otp, setOTP] = useState('');
    const [userId, setUserId] = useState('');
    const [showOtpInput, setShowOtpInput] = useState(false);
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const endPoint = 'https://webai-forex-backend-ashy.vercel.app'
    const [otp, setOtp] = useState(new Array(6).fill(''));
    const inputRefs = useRef([]);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };


    const handleChange = (e, idx) => {
        const value = e.target.value;
        if (/^[0-9]?$/.test(value)) {  // Only allow a single digit
            const newOtp = [...otp];
            newOtp[idx] = value;
            setOtp(newOtp);

            // Move to the next input if the current one is filled
            if (value && idx < otp.length - 1) {
                inputRefs.current[idx + 1].focus();
            }
        }
    };

    const handleKeyDown = (e, idx) => {
        if (e.key === 'Backspace' && !otp[idx] && idx > 0) {
            inputRefs.current[idx - 1].focus();
        }
    };


    const backgroundStyle = {
        height: '100vh',
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    };

    // Firebase Signup and API call for OTP verification
    const handleFirebaseSignup = async () => {
        try {
            // Create a new user in Firebase Authentication
            await createUserWithEmailAndPassword(auth, email, password);

            // Make API call to register user details and get userId for OTP verification
            const response = await axios.post(`${endPoint}/api/auth/signup`, {
                email,
                username: name,
                traderId,
                password,
                mobileNumber: "1234567890"
            });
            setUserId(response.data.userId);
            console.log('Upcoming user id: ', response.data.userId);
            console.log('Response: ', response)
            setShowOtpInput(true);
            toast.success(response.data.message, { position: "top-right", autoClose: 5000, theme: "light", transition: Bounce });
        } catch (error) {
            toast.error('Signup failed. Please try again.', { position: "top-right", autoClose: 5000, theme: "light", transition: Bounce });
            console.error(error);
        }
    };

    const handleOtpVerification = async () => {
        try {
            const response = await axios.post(`${endPoint}/api/auth/verify-otp`, {
                userId: userId,
                otp: otp.join('') // Convert the OTP array to a string
            });
            toast.success(response.data.message, { position: "top-right", autoClose: 5000, theme: "light", transition: Bounce });
            navigate('/accountUnderVerification');
        } catch (error) {
            toast.error('OTP verification failed. Please try again.', { position: "top-right", autoClose: 5000, theme: "light", transition: Bounce });
            console.error(error);
        }
    };

    useEffect(() => {
        // alert('Api endpoint', `${endPoint}/api/auth/verify-otp`)
        console.log('User id: ', userId)
    }, [])

    return (
        <div style={backgroundStyle}>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick pauseOnFocusLoss draggable pauseOnHover theme="light" transition={Bounce} />
            <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50" onClick={() => navigate('/')}>
                <div className="relative w-[90%] max-w-[900px]  max-h-[650px] bg-[#FAFBFC] rounded-lg p-8 shadow-2xl" onClick={(e) => e.stopPropagation()}>
                    <div className='flex mt-2 mb-[18px] md:mb-0 md:mt-4 justify-between'>
                        {/* <h1 className="text-[24px] md:text-[28px] font-custom leading-[28px] font-bold text-[#190041]">
                            Forexyy
                        </h1> */}
                        <div>
                        <img src="mainlogos.svg" alt="" />
                        </div>
                        <button
                            onClick={() => navigate('/login')}
                            type="button"
                            className="text-white bg-[#3EBA59] hover:bg-[#35a94f] focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-3 py-2.5 text-center shadow-lg"
                        >
                            Login
                        </button>
                    </div>

                    <div className="absolute bottom-0 left-0 -mr-[1px] -mb-[3.89px]">
                        <img src={LoginBg} alt="Background" className="hidden md:block" />
                    </div>

                    <div className='w-full md:w-[450px] h-auto py-4 relative px-5 rounded-lg bg-white shadow-lg mx-auto'>
                        <form>
                            <h2 className="text-[#292D34] font-custom text-[20px] md:text-[25.6px] font-bold leading-[36.8px] text-center mb-4">
                                SIGN UP
                            </h2>
                            {!showOtpInput ? (
                                <>
                                    <div className="mb-4">
                                        <div className="relative">
                                            <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                                                <img src={TaderId} alt="Trader ID" className="w-6 h-6" />
                                            </span>
                                            <input
                                                className="shadow text-[14px] appearance-none border rounded w-full py-3 px-3 pl-10 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="traderId"
                                                type="text"
                                                value={traderId}
                                                onChange={(e) => setTraderId(e.target.value)}
                                                placeholder="Enter trader id"
                                            />
                                        </div>
                                        <p style={{ fontSize: '13px', textAlign: 'left', color: '#B8B8B8', marginTop: '2%' }}>Need a Trader ID? Click 'Generate ID' below</p>
                                    </div>

                                    <div className="mb-6">
                                        <div className="relative">
                                            <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                                                <img src={TaderId} alt="Trader ID" className="w-6 h-6" />
                                            </span>
                                            <input
                                                className="shadow text-[14px] appearance-none border rounded w-full py-3 px-3 pl-10 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="name"
                                                type="text"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                placeholder="Enter your name"
                                            />
                                        </div>
                                    </div>

                                    <div className="relative mb-4">
                                        <div className='relative'>
                                            <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                                                <img src={Lock} alt="Trader ID" className="w-5 h-5" />
                                            </span>
                                            <input
                                                className="shadow text-[14px] appearance-none border rounded w-full py-3 px-3 pl-9 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="password"
                                                type={showPassword ? 'text' : 'password'}
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                placeholder="Create Password"
                                            />
                                            <div
                                                className="absolute inset-y-0 right-0 flex items-center px-3 cursor-pointer text-gray-500"
                                                onClick={togglePasswordVisibility}
                                            >
                                                {showPassword ? <FiEye /> : <FiEyeOff />}
                                            </div>
                                        </div>
                                        <p style={{ fontSize: '13px', textAlign: 'left', color: '#B8B8B8', marginTop: '2%' }}>At least 8 characters, with numbers and symbols.</p>

                                    </div>

                                    <div className="mb-4">
                                        <div className='relative'>
                                            <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                                                <img src={Email} alt="Trader ID" className="w-5 h-5" />
                                            </span>
                                            <input
                                                className="shadow text-[14px] appearance-none border rounded w-full py-3 px-3 pl-9 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="email"
                                                type="email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                placeholder="Enter email"
                                            />
                                        </div>
                                        <p style={{ fontSize: '13px', textAlign: 'left', color: '#B8B8B8', marginTop: '2%' }}>An otp will be sent to your registered email</p>
                                    </div>
                                    <button
                                        type="button"
                                        className="w-full p-4 text-[14px] bg-[#3EBA59] text-white rounded-lg hover:bg-green-500 mb-4"
                                        onClick={handleFirebaseSignup}
                                    >
                                        Sign Up
                                    </button>
                                    <div>
                                        <p style={{ fontSize: '14.5px', textAlign: 'center', color: 'gray' }}>Don’t have an trader id? <a style={{ fontWeight: 'bold' }} href='https://broker-qx.pro/sign-up/?lid=536809'>Generate ID</a></p>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="mb-4">
                                        <label htmlFor="otp" className="block text-[13px] font-semibold text-gray-700 mb-4">
                                            Verification Code
                                        </label>
                                        <div className="flex justify-between gap-1 text-black">
                                            {otp.map((digit, idx) => (
                                                <input
                                                    key={idx}
                                                    type="text"
                                                    maxLength="1"
                                                    value={digit}
                                                    onChange={(e) => handleChange(e, idx)}
                                                    onKeyDown={(e) => handleKeyDown(e, idx)}
                                                    ref={(el) => (inputRefs.current[idx] = el)}
                                                    className="md:w-10 md:h-10 w-8 h-8 text-center p-2 border border-gray-300 rounded-md"
                                                />
                                            ))}
                                        </div>
                                    </div>
                                    <button
                                        type="button"
                                        className="w-full p-4 text-[14px] bg-[#3EBA59] text-white rounded-lg hover:bg-green-500 mb-4"
                                        onClick={handleOtpVerification}
                                    >
                                        Verify OTP
                                    </button>
                                </>
                            )}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignUp;
