import React, { useContext } from "react";
import { GrLocation } from "react-icons/gr";
import MemberChatIcon from "../Assets/MembersChat.svg"
import Department from "../Assets/Department.svg"
import Analysis from "../Assets/Analysis.svg"
import FundedAccount from "../Assets/FundedAccount.svg"
import Notifications from "../Assets/Notifications.svg"
import Settings from "../Assets/Settings.svg"
import Support from "../Assets/SuportIcon.svg"
import { useStateContext } from "../Contexts/ContextProvider";



export const gridOrderImage = (props) => (
  <div>
    <img
      className="rounded-xl h-20 md:ml-3"
      src={props.ProductImage}
      alt="order-item"
    />
  </div>
);

export const gridOrderStatus = (props) => (
  <button
    type="button"
    style={{ background: props.StatusBg }}
    className="text-white py-1 px-2 capitalize rounded-2xl text-md"
  >
    {props.Status}
  </button>
);

export const kanbanGrid = [
  { headerText: "To Do", keyField: "Open", allowToggle: true },

  { headerText: "In Progress", keyField: "InProgress", allowToggle: true },

  {
    headerText: "Testing",
    keyField: "Testing",
    allowToggle: true,
    isExpanded: false,
  },

  { headerText: "Done", keyField: "Close", allowToggle: true },
];
const gridEmployeeProfile = (props) => (
  <div className="flex items-center gap-2">
    <img
      className="rounded-full w-10 h-10"
      src={props.EmployeeImage}
      alt="employee"
    />
    <p>{props.Name}</p>
  </div>
);

const gridEmployeeCountry = (props) => (
  <div className="flex items-center justify-center gap-2">
    <GrLocation />
    <span>{props.Country}</span>
  </div>
);
export const EditorData = () => (
  <div>
    <h3>
      Try React React has been designed from the start for gradual adoption, and
      you can use as little or as much React as you need. Whether you want to
      get a taste of React, add some interactivity to a simple HTML page, or
      start a complex React-powered app, the links in this section will help you
      get started. Online Playgrounds If you’re interested in playing around
      with React, you can use an online code playground. Try a Hello World
      template on CodePen, CodeSandbox, or Stackblitz. If you prefer to use your
      own text editor, you can also download this HTML file, edit it, and open
      it from the local filesystem in your browser. It does a slow runtime code
      transformation, so we’d only recommend using this for simple demos. Add
      React to a Website You can add React to an HTML page in one minute. You
      can then either gradually expand its presence, or keep it contained to a
      few dynamic widgets. Create a New React App When starting a React project,
      a simple HTML page with script tags might still be the best option. It
      only takes a minute to set up! As your application grows, you might want
      to consider a more integrated setup. There are several JavaScript
      toolchains we recommend for larger applications. Each of them can work
      with little to no configuration and lets you take full advantage of the
      rich React ecosystem. Learn how. Learn React People come to React from
      different backgrounds and with different learning styles. Whether you
      prefer a more theoretical or a practical approach, we hope you’ll find
      this section helpful. If you prefer to learn by doing, start with our
      practical tutorial. If you prefer to learn concepts step by step, start
      with our guide to main concepts. Like any unfamiliar technology, React
      does have a learning curve. With practice and some patience, you will get
      the hang of it. First Examples The React homepage contains a few small
      React examples with a live editor. Even if you don’t know anything about
      React yet, try changing their code and see how it affects the result.
      React for Beginners If you feel that the React documentation goes at a
      faster pace than you’re comfortable with, check out this overview of React
      by Tania Rascia. It introduces the most important React concepts in a
      detailed, beginner-friendly way. Once you’re done, give the documentation
      another try! React for Designers If you’re coming from a design
      background, these resources are a great place to get started. JavaScript
      Resources The React documentation assumes some familiarity with
      programming in the JavaScript language. You don’t have to be an expert,
      but it’s harder to learn both React and JavaScript at the same time. We
      recommend going through this JavaScript overview to check your knowledge
      level. It will take you between 30 minutes and an hour but you will feel
      more confident learning React.
    </h3>
  </div>
);
const customerGridImage = (props) => (
  <div className="image flex gap-4">
    <img
      className="rounded-full w-10 h-10"
      src={props.CustomerImage}
      alt="employee"
    />
    <div>
      <p>{props.CustomerName}</p>
      <p>{props.CustomerEmail}</p>
    </div>
  </div>
);

const customerGridStatus = (props) => (
  <div className="flex gap-2 justify-center items-center text-gray-700 capitalize">
    <p
      style={{ background: props.StatusBg }}
      className="rounded-full h-3 w-3"
    />
    <p>{props.Status}</p>
  </div>
);
export const areaPrimaryXAxis = {
  valueType: "DateTime",
  labelFormat: "y",
  majorGridLines: { width: 0 },
  intervalType: "Years",
  edgeLabelPlacement: "Shift",
  labelStyle: { color: "gray" },
};

export const areaPrimaryYAxis = {
  labelFormat: "{value}%",
  lineStyle: { width: 0 },
  maximum: 4,
  interval: 1,
  majorTickLines: { width: 0 },
  minorTickLines: { width: 0 },
  labelStyle: { color: "gray" },
};
export const barPrimaryXAxis = {
  valueType: "Category",
  interval: 1,
  majorGridLines: { width: 0 },
};
export const barPrimaryYAxis = {
  majorGridLines: { width: 0 },
  majorTickLines: { width: 0 },
  lineStyle: { width: 0 },
  labelStyle: { color: "transparent" },
};
const areaChartData = [
  [
    { x: new Date(2002, 0, 1), y: 2.2 },
    { x: new Date(2003, 0, 1), y: 3.4 },
    { x: new Date(2004, 0, 1), y: 2.8 },
    { x: new Date(2005, 0, 1), y: 1.6 },
    { x: new Date(2006, 0, 1), y: 2.3 },
    { x: new Date(2007, 0, 1), y: 2.5 },
    { x: new Date(2008, 0, 1), y: 2.9 },
    { x: new Date(2009, 0, 1), y: 3.8 },
    { x: new Date(2010, 0, 1), y: 1.4 },
    { x: new Date(2011, 0, 1), y: 3.1 },
  ],
  [
    { x: new Date(2002, 0, 1), y: 2 },
    { x: new Date(2003, 0, 1), y: 1.7 },
    { x: new Date(2004, 0, 1), y: 1.8 },
    { x: new Date(2005, 0, 1), y: 2.1 },
    { x: new Date(2006, 0, 1), y: 2.3 },
    { x: new Date(2007, 0, 1), y: 1.7 },
    { x: new Date(2008, 0, 1), y: 1.5 },
    { x: new Date(2009, 0, 1), y: 2.8 },
    { x: new Date(2010, 0, 1), y: 1.5 },
    { x: new Date(2011, 0, 1), y: 2.3 },
  ],
  [
    { x: new Date(2002, 0, 1), y: 0.8 },
    { x: new Date(2003, 0, 1), y: 1.3 },
    { x: new Date(2004, 0, 1), y: 1.1 },
    { x: new Date(2005, 0, 1), y: 1.6 },
    { x: new Date(2006, 0, 1), y: 2 },
    { x: new Date(2007, 0, 1), y: 1.7 },
    { x: new Date(2008, 0, 1), y: 2.3 },
    { x: new Date(2009, 0, 1), y: 2.7 },
    { x: new Date(2010, 0, 1), y: 1.1 },
    { x: new Date(2011, 0, 1), y: 2.3 },
  ],
];

export const areaCustomSeries = [
  {
    dataSource: areaChartData[0],
    xName: "x",
    yName: "y",
    name: "USA",
    opacity: "0.8",
    type: "SplineArea",
    width: "2",
  },
  {
    dataSource: areaChartData[1],
    xName: "x",
    yName: "y",
    name: "France",
    opacity: "0.8",
    type: "SplineArea",
    width: "2",
  },
  {
    dataSource: areaChartData[2],
    xName: "x",
    yName: "y",
    name: "Germany",
    opacity: "0.8",
    type: "SplineArea",
    width: "2",
  },
];

export const barChartData = [
  [
    { x: "USA", y: 46 },
    { x: "GBR", y: 27 },
    { x: "CHN", y: 26 },
  ],
  [
    { x: "USA", y: 37 },
    { x: "GBR", y: 23 },
    { x: "CHN", y: 18 },
  ],
  [
    { x: "USA", y: 38 },
    { x: "GBR", y: 17 },
    { x: "CHN", y: 26 },
  ],
];

export const barCustomSeries = [
  {
    dataSource: barChartData[0],
    xName: "x",
    yName: "y",
    name: "Gold",
    type: "Column",
    marker: {
      dataLabel: {
        visible: true,
        position: "Top",
        font: { fontWeight: "600", color: "#ffffff" },
      },
    },
  },
  {
    dataSource: barChartData[1],
    xName: "x",
    yName: "y",
    name: "Silver",
    type: "Column",
    marker: {
      dataLabel: {
        visible: true,
        position: "Top",
        font: { fontWeight: "600", color: "#ffffff" },
      },
    },
  },
  {
    dataSource: barChartData[2],
    xName: "x",
    yName: "y",
    name: "Bronze",
    type: "Column",
    marker: {
      dataLabel: {
        visible: true,
        position: "Top",
        font: { fontWeight: "600", color: "#ffffff" },
      },
    },
  },
];
export const colorMappingData = [
  [
    { x: "Jan", y: 6.96 },
    { x: "Feb", y: 8.9 },
    { x: "Mar", y: 12 },
    { x: "Apr", y: 17.5 },
    { x: "May", y: 22.1 },
    { x: "June", y: 25 },
    { x: "July", y: 29.4 },
    { x: "Aug", y: 29.6 },
    { x: "Sep", y: 25.8 },
    { x: "Oct", y: 21.1 },
    { x: "Nov", y: 15.5 },
    { x: "Dec", y: 9.9 },
  ],
  ["#FFFF99"],
  ["#FFA500"],
  ["#FF4040"],
];

export const rangeColorMapping = [
  { label: "1°C to 10°C", start: "1", end: "10", colors: colorMappingData[1] },

  {
    label: "11°C to 20°C",
    start: "11",
    end: "20",
    colors: colorMappingData[2],
  },

  {
    label: "21°C to 30°C",
    start: "21",
    end: "30",
    colors: colorMappingData[3],
  },
];

export const ColorMappingPrimaryXAxis = {
  valueType: "Category",
  majorGridLines: { width: 0 },
  title: "Months",
};

export const ColorMappingPrimaryYAxis = {
  lineStyle: { width: 0 },
  majorTickLines: { width: 0 },
  minorTickLines: { width: 0 },
  labelFormat: "{value}°C",
  title: "Temperature",
};

export const FinancialPrimaryXAxis = {
  valueType: "DateTime",
  minimum: new Date("2016, 12, 31"),
  maximum: new Date("2017, 9, 30"),
  crosshairTooltip: { enable: true },
  majorGridLines: { width: 0 },
};

export const FinancialPrimaryYAxis = {
  title: "Price",
  minimum: 100,
  maximum: 180,
  interval: 20,
  lineStyle: { width: 0 },
  majorTickLines: { width: 0 },
};

export const LinePrimaryXAxis = {
  valueType: "DateTime",
  labelFormat: "y",
  intervalType: "Years",
  edgeLabelPlacement: "Shift",
  majorGridLines: { width: 0 },
  background: "white",
};

export const LinePrimaryYAxis = {
  labelFormat: "{value}%",
  rangePadding: "None",
  minimum: 0,
  maximum: 100,
  interval: 20,
  lineStyle: { width: 0 },
  majorTickLines: { width: 0 },
  minorTickLines: { width: 0 },
};

export const customersGrid = [
  { type: "checkbox", width: "50" },
  {
    headerText: "Name",
    width: "150",
    template: customerGridImage,
    textAlign: "Center",
  },
  {
    field: "ProjectName",
    headerText: "Project Name",
    width: "150",
    textAlign: "Center",
  },
  {
    field: "Status",
    headerText: "Status",
    width: "130",
    format: "yMd",
    textAlign: "Center",
    template: customerGridStatus,
  },
  {
    field: "Weeks",
    headerText: "Weeks",
    width: "100",
    format: "C2",
    textAlign: "Center",
  },
  {
    field: "Budget",
    headerText: "Budget",
    width: "100",
    format: "yMd",
    textAlign: "Center",
  },

  {
    field: "Location",
    headerText: "Location",
    width: "150",
    textAlign: "Center",
  },

  {
    field: "CustomerID",
    headerText: "Customer ID",
    width: "120",
    textAlign: "Center",
    isPrimaryKey: true,
  },
];

export const employeesGrid = [
  {
    headerText: "Employee",
    width: "150",
    template: gridEmployeeProfile,
    textAlign: "Center",
  },
  { field: "Name", headerText: "", width: "0", textAlign: "Center" },
  {
    field: "Title",
    headerText: "Designation",
    width: "170",
    textAlign: "Center",
  },
  {
    headerText: "Country",
    width: "120",
    textAlign: "Center",
    template: gridEmployeeCountry,
  },

  {
    field: "HireDate",
    headerText: "Hire Date",
    width: "135",
    format: "yMd",
    textAlign: "Center",
  },

  {
    field: "ReportsTo",
    headerText: "Reports To",
    width: "120",
    textAlign: "Center",
  },
  {
    field: "EmployeeID",
    headerText: "Employee ID",
    width: "125",
    textAlign: "Center",
  },
];
export const links = [
  {
    links: [
      {
        name: "Signal",
        url: "membersChat",
        icon: <img src={MemberChatIcon} className='w-5 h-5' />,
      },
      {
        name: "Courses",
        url: "courses",
        icon: <img src={FundedAccount} className='w-5 h-5' />,
      },
      {
        name: "Analysis",
        url: "analysis",
        icon: <img src={Analysis} className='w-5 h-5' />,
      },
      {
        name: "Notifications",
        url: "notifications",
        icon: <img src={Notifications} className='w-5 h-5' />,
      },
      {
        name: "Settings",
        url: "settings",
        icon: <img src={Settings} className='w-5 h-5' />,
      },
      {
        name: "Support",
        url: "support",
        icon: <img src={Support} className='w-5 h-5' />,
      },
    ],
  },
];


export const coursesData = [
  {
    id: Date.now(),
    img: "https://s3-alpha-sig.figma.com/img/3d9b/deb8/b7791d592cdbbcd265853b418f13ad29?Expires=1729468800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=OzC~oUBstCpsL9qj16ZOWEQhDQP7kFrHDHxwjzlPq9aRpx287qWXKFuzZ1-2EtkvbN1Xau2pV6CB~3ti1U60TjsAYKy2cxzyhtwiKJaJkQRfDejeE5cYLwd1OrVCFibgV1XdGbsfa2nW6eIW~t9tBt55GnLVWnu0EGZotuMiiZ-VhNef6ph77UAH4KHLsm3XDKtoMGdu9tcgqehW8rXSmnOjh9deJN0bWswTeKVGZ7ca8IMzSatS~pp55pSGrDciwhq0rmA2ZVzougjVIAFaHg4yonIH7NlgMLvMDuXUBIx7b5hyyCIk-wROqpACTsFeYTs31qg0kZO8zpuIXu9KdQ__",
    title: "Learn Trading - Trading Training",
    lessonIcon: "/lesson.png",
    lesson: "Lesson : 6",
    studentIcon: "/lesson.png",
    student: "student : 198",
    levelIcon: "/beginner.png",
    level: "Beginner",
    cta: "Start course",
    scoreIcon: "/score.png",
    ratings: 4,
  },
  {
    id: Date.now(),
    img: "https://s3-alpha-sig.figma.com/img/fcc6/55c2/ddf6c99af9b15609159487a2d6e0b935?Expires=1729468800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=PSfMBJazvBAyAMtmd4XmATkNPkYVXyI9MVF9pzSVKLNvSqmFtolF5jcOIkbfgEJsBdYVnD~NmIR-QmMWI8Qgu2LaVWd9B1GvBcQ0nXb7KisOEcImSMTJZYmVoVfw77KxUqmKBM0-CC9phQSajIYkM1yq8toDqXfzPdQKghHx~nsg15q~6a-uPnZ0L6JJwliJph2OTgCEJZnoSoceLqt8tm27yMDr6UIpK-Y2~IUmLW1n4mcjcI4TxY7V4Y0-LTcKdtNtTUk3TpGJ2WW1F6kRuJzuhBi-VZ4Zwtc-c0UDk1yxGiLZzuttGNrpo6VhsMutb1t8HQmSLG~kFo9egkQZFw__",
    title: "Python for Beginners - Learn Programming",
    lessonIcon: "/lesson.png",
    lesson: "Lesson : 21",
    studentIcon: "/lesson.png",
    student: "student : 99",
    levelIcon: "/beginner.png",
    level: "Advanced",
    cta: "Start course",
    scoreIcon: "/score.png",
    ratings: 3,
  },
  {
    id: Date.now(),
    img: "https://s3-alpha-sig.figma.com/img/efc3/644d/d6f5496d7c14e425464f7213710b003b?Expires=1729468800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=crw1atmhibvtzQR2t5O0LCxyX1irDLp4GUyufvC7XLFPisIur0Q8Uyn5jMWOUsQTQnZPZSz2w1HR0sm1JlqJKsmGrMDS9UNobh4R1VhTbXUFfJ98JR2HJUCT5dL6MKyF0AGqKG-iRV-vqSf4XGy-J1i~N75sOI5S8iB0FepCJg3mGfvqZLvpCzqXeRiHfK8If9eInLJtQbcen7ajnAuwXNeW-bVryGYoVRghhovZArBLAbkSWs1xHnbbjQmODcwxlvtL53nQhzsyWP3a2rFe0f1npGtqgEz0bWhz90tnCgSl3bqvtUNqaBTheJBEoPme7bPwW9g5l96voho40pS9~A__",
    title: "Acoustic Guitar and Electric Guitar Started",
    lessonIcon: "/lesson.png",
    lesson: "Lesson : 8",
    studentIcon: "/lesson.png",
    student: "student : 301",
    levelIcon: "/beginner.png",
    level: "average",
    cta: "Start course",
    scoreIcon: "/score.png",
    ratings: 5,
  },
  {
    id: Date.now(),
    img: "https://s3-alpha-sig.figma.com/img/afb5/59f8/1206fc2266e4ca1ce45a7d716621ab48?Expires=1729468800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=ixoz76HoV1A2MbXCk2DEIDYMBM6wxc7KgIG9JbkvxCabY3QqzsBlAE4KfAoMvosUzIcBSxQq11Xer129WATYUxwcJ0nnFJgUFBi2UBePYc-KZSEZ8c4TKKXmosbykWo9mDeG8UMK9F1VaGxb8x2~JxPNwnEiHPoyHVNUlOc4-F6jc2VqO2vTHh2N3Fid2tJ4AYSUI5mBvKZWByVQmz-YTb2-teoSFpllb1eieu1WfrE~iOYwv8TvDyCmpoksTCqTxSWBYaGLLZCB2pVtTNezL1C2zd5r3tPqcVa9sunnHgNdqtyItQE7NibVLCzQttEhyBUCIJAxhuSTno8ui~SnZQ__",
    title: "Mobile App Development with Flutter & Dart",
    lessonIcon: "/lesson.png",
    lesson: "Lesson : 15",
    studentIcon: "/student.png",
    student: "student : 215",
    level: "Beginner",
    cta: "Start course",
    scoreIcon: "/score.png",
    ratings: 2,
  },
  {
    id: Date.now(),
    img: "https://s3-alpha-sig.figma.com/img/8e45/25bd/a0f4d6ef0d90bb13c0441904a43f3c7a?Expires=1729468800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=jKvYDcSFno3MHExzDVxLhxQUDuw9WjLeIcifCdGyCkI4cPcCAX-~nX~BF0c5-38~HhPWy-8PT~CrQzYM8W5U74sZJHIH3ZWS3RskbiEH59XFJKZgy9CJntMUYiuZRtsVGXIbO5W6jaQi7B4PHbQzDKq4g9nDO8OxpT63K-nWjyLY-oJvn4wQY7wgYjWAfm6CA7IevdXt9L-ILOekNcRK5D-u6qxede4hG4e7tCu9TeR5zfJTQr1yvtdTvTfBAEYyJnuVRt4608nRTu0WbrbqPdLK5RyPHCqARAmOH9XptTVxJKecPEvkUb30DLs5ToP2zEpccD4qFV2PCNAjdW-iJg__",
    title: "Ionic React: Mobile Development with Ionic",
    lessonIcon: "/lesson.png",
    lesson: "Lesson : 15",
    studentIcon: "/student.png",
    student: "student : 67",
    level: "Advanced",
    cta: "Start course",
    scoreIcon: "/score.png",
    ratings: 5,
  },
  {
    id: Date.now(),
    img: "https://s3-alpha-sig.figma.com/img/5683/1cce/3f30240f6e1f0680718aba829ce223f2?Expires=1729468800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=IgxXQYSzXdOaXjDngSC5zeMmjmh6HIPGI6XEmTu-v-E2If2Z8mVQAlnvrC-c2WcW4l~wMJxDuVt4UuFGPHkKwLjNJEd~IQ-kAcYsZNLQ5Z92euEQLMCK177aWUX33b8NwUSX2DmDiqPB2lMUBHVAcUfCVywAWA~yNSNYp0gehq7zGduSafyeibyyc0uALj07PgKxsfCiAR37Upbh7jc0A8~vF4sd1otkzJFXeKc04zxZ30DcSJ3Hyl-w8zLntP7z04dsZt-y59s~-SL4Y6Syl34VE6W-SWUIyoiAXCMpw4w9~FIbUS~pbHPRPuJ0GT7B8-WKgJFEL~EM2qpdEH3u1w__",
    title: "Sports Management: The Essentials Course",
    lessonIcon: "/lesson.png",
    lesson: "Lesson : 26",
    studentIcon: "/student.png",
    student: "student : 156",
    level: "average",
    cta: "Start course",
    scoreIcon: "/score.png",
    ratings: 1,
  },
  {
    id: Date.now(),
    img: "https://s3-alpha-sig.figma.com/img/b3b8/a3b0/062a0d6d745e48459666cca5fa0b2b73?Expires=1729468800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Y9H0z9qI-5lFHwXwURfWGq1giuTri2YKPksrToMDBiUodG10qCzS2jcTF~XtgOpv~pIHbMcl1wAftGJfz~NiJ9jIvp0ED6PBuRhlHOajvdZJxH01YbEUN7Y7MSynolqM1dA-A5KI9YXlsSZSO0vD9qKTq5y0yqW~nswv3p2zghJpy~y-kHBJSsybPS91XY0pedfD4gGIdMfPIGyzq0WN14YOMEAmc9iQ2hF3ziu8iIJgkZihEhJNYfTvfx12m-rErXSYcfYO8ZSYpzgwe5EZouKKVK513pEpOUxOIyTfv96INd7sEQqo7IREJal9zvHnvzd98sh8gGbzgGYZkWnz9g__",
    title: "How to Market Yourself as a Consultant",
    lessonIcon: "/lesson.png",
    lesson: "Lesson : 33",
    studentIcon: "/student.png",
    student: "student : 64",
    level: "Beginner",
    cta: "Start course",
    scoreIcon: "/score.png",
    ratings: 3,
  },
  {
    id: Date.now(),
    img: "https://s3-alpha-sig.figma.com/img/651b/e78e/a3da85590932d82876b01d3a055e21b8?Expires=1729468800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=ikuQTzrfyYIraMmqcJHLk5DrpKpWegoywig6GaeEOI6mXxlUMHAtVzv0VtkHIqBOFxFARcc2kAMhQemWKxjsVCl5EM-ED-BLCiopODn5p4Ounro7IhwYZjQwq7ilzEFjTAexrgJQzZzjgUQC~LIUnKsSdkQZTxmQG5pYvkosTaUD14qzJ~WAwIsgZeNn6HywqANu3ZKo3r3XxfA41-p9-F5bBsbQIGQewUDtzuD3grHbztzOMRAy9MHl96c2sXuLs5hmRig~TqypGdkcnERqooPCBzNdEfCeK-Xc7g~VvAEOjwLJZ7WQofSXnS~JehTqWbdIVZP-dLDgjF13K1tY-g__",
    title: "Become a Product Manager | Learn the Skill",
    lessonIcon: "/lesson.png",
    lesson: "Lesson : 5",
    studentIcon: "/student.png",
    student: "student : 134",
    level: "Advanced",
    cta: "Start course",
    scoreIcon: "/score.png",
    ratings: 4,
  },
];
export const analysisBottomCards = [
  {
    id: Date.now(),
    img: "https://s3-alpha-sig.figma.com/img/ce2d/f1a2/c242b8828c39e8c3610fbfb1f0aebc8e?Expires=1729468800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=RrboX7P~DU~zTzddO0Ft9A08j9yybAKafA-eUHiZ9TI4npEVXWxtoQLSVlfkkyhCoi-b7UGWSmJ24GUfkywKJxyhDe~7wfDhpVMO3hvqglNs9m76XKbriyp8-qjYhRGKIB3Kdh6BStyPNROU2uZBNjrim-B513TBvM0Fd2OVHgB2DYVYF~nhcVgOIBl8ZfZ6b2~BDvZiOAE8k6q6RLRdht44CPSu4GqJDjW-EGfAb8zQAHoasNg00UgSS4EpPvKcI9-OmvhAlKD99gGx-sk6-WLgyjcfbV1aqx5GPxFTmmKOSfQ1Scf8Dn8PQg6EFgwsHHTXOdnYt-DASFYIHdUB8w__",
    title: "BTC took the support from the trending and give an upward move.",
    subTitle: "Open account and get eligible for free course",
    description:
      "BTC took the support from the trending and give an upward move.",
    subDescription:
      "BTC took the support from the trending and give an upward move.",
  },
  {
    id: Date.now(),
    img: "https://s3-alpha-sig.figma.com/img/9c06/8822/ba1dd5146ef7ffabcc9d97dc8a80826c?Expires=1729468800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=IG-ZAt1wMrM3ZO28gH7o9DAxftAEX5841dqcusnrc60aI78GMZQBp~ZezPQTfmED7ckuiZbdBF3scGk41Azt233vk9xFhKO6wqMerYAVq7XV5n9XjssZbomAKz1HGfSLiWDxTsJmbk5Y63sCRROwHH2ad19hMOcTsmFL5o12BkzJ~lrqNJ4-XKDLIAtWiyHEJMDX08Jv9h-xx5ToBfbX2LxXcFFB6fJ65UBtxv7QvsWgRHtpSZO05nNY46-ohX1~JsVjHCmvpWYHAIETYcgfw09ZGmWHRqIUZoYWGi8PKgeu7hwbP7Iu8yoU2VBlz2N0u0f4s~QBb7FDq~KxiSo-7A__",
    title: "BTC took the support from the trending and give an upward move.",
    subTitle: "Open account and get eligible for free course",
    description:
      "BTC took the support from the trending and give an upward move.",
    subDescription:
      "BTC took the support from the trending and give an upward move.",
  },
  {
    id: Date.now(),
    img: "https://s3-alpha-sig.figma.com/img/7469/008b/5ceb7aad960977453e37e630614320f1?Expires=1729468800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=FxXS2zhiOrIwiv7JbujUxI9CPRxOtHt5AKUtZKjnkUMhDxFThEq8~aoEjy9MBVGbeuuoUjBG6HB7v16Xy9vSlWEbyTjXX8DASTfiIqn-tGEbWUF0bq1aqUaBYVBI~KH4592la0hOdoZTJX-peZSJNCOIRcoTVuLHAt-o-cAA~5NmDjCpgaQKfgsJ4DhUONbMF6fvC02Ncljqh6dSVQD33-vmNrtvqVSe8DjUdpgVOgAR9yLrM-ddPyZgL7IFmpJz-m~Ltmpodw4Gx~PIHdLj5pkikLF2hBSwTta2MyiDEBjh2Xw5qk8CALtjCUwsZMKMP9WrjqpSISH1wtRVTfABXw__",
    title: "BTC took the support from the trending and give an upward move.",
    subTitle: "Open account and get eligible for free course",
    description:
      "BTC took the support from the trending and give an upward move.",
    subDescription:
      "BTC took the support from the trending and give an upward move.",
  },
  {
    id: Date.now(),
    img: "https://s3-alpha-sig.figma.com/img/2f97/21c1/f7902d2a4f887898f5a63a2a5ba5d00f?Expires=1729468800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=cAcAw3PxbMewumGnUJVHBnkxJ1-lgUcLetaIMEzBISnOgY-yigSnE9mzj5apHdgbObK-T91Yq8PUBNaJKJ0Qfm2bFftMnghoN1LyYiq2ej9kpRmtC2G9AEt7JsgO~xzzQYw2urRPbHGdO35mb8XRJYt~fTyjHp50gwWzDd-VAlVeTWn1rWJ3bjVJemw1y8qL5R7k8INnvPYeXcZllZP43ffBokL-js3UVD4j8HCeddf1nZ8Owi-nRNWdtFekSSzVo6pm7zbG6HahD-ruKCEBQxtChFi6RcyVFBuIKP5xwPT36xkyKI24pAVd8ZA91YBNoTjVSGvEMkQaVHw4m704zw__",
    title: "BTC took the support from the trending and give an upward move.",
    subTitle: "Open account and get eligible for free course",
    description:
      "BTC took the support from the trending and give an upward move.",
    subDescription:
      "BTC took the support from the trending and give an upward move.",
  },
];

export const analysisTopCards = [
  {
    id: Date.now(),
    img: "https://s3-alpha-sig.figma.com/img/caa9/76c1/83f73c1a469739eb18866d97b95bf69f?Expires=1729468800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=csif5Y4OSTeV2o3~LvomEKDXyvm06Dx0x3Caent-RJ0tYPF5iSis5vE1lgAT4kkNkbn-eVrlTKqGOXyC--ovdRoRaeQj-blMkiEtoLw6aVQ3KFokUGfSJhWFc1ASdqEfyL1xUN0z2kTcq8A28M20pIwDlNXTxvEMnD6U2zg6sd4UAL4epxcfSxbSUZZCstfiDOkFmDDdL-PHaz9KKVrate7xM9w9JvDpZ5wdmWsTYPt8jTY6hQb8crWs2CvDYuCkrayyTa7ER1J1j38ClnHzhrQ6H8d1H0X~jxQLUCiC81Ry3svjW5LIJ3FicbNI2BsiFujaoL1z-m5dg6LPMXABxA__",
    title:
      "Nifty is taking support from the trending and the next resistance zone will be around 25130.",
  },
  {
    id: Date.now(),
    img: "https://s3-alpha-sig.figma.com/img/09e5/6f7f/1c6dd9b927026503e33824a88cfebd82?Expires=1729468800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Z-f19I2iet2TT8zmJS6QITFhPxqu8tZXE3QwO3yjizx6qa0JCzeWbtFjMCrIz60FCAY-qYALvqQTOnWeHpgJVWzml6UWo7kZ5Jd2C0L2RSofJTnhNsHrSsxW2xhSuKkbjcro2UtHWYS5bm3Z9IpKyWrHt8TgjU6Ynma0pSTwrFOJdqBvG4zkMGK1fuvePcjayoGbQ3gkmrJKia7tlGS7C73qAKoU0VnFntKxCF0GavoCPn9CGamEilIXYBfwb3OTCw1Q~CbfgeeUkaqQHENRKQKW1N9kpnF9gSr7E46TBDGQqZl~i3BMvJR0Ha2lPM83uT8yKeHLyBB56dpih0J7jw__",
    title:
      "Nifty is taking support from the trending and the next resistance zone will be around 25130.",
  },
  {
    id: Date.now(),
    img: "https://s3-alpha-sig.figma.com/img/1c80/517b/8975785cab509a07641ddcb25033db96?Expires=1729468800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=kl5P-1H0UZOaHH4BBDfzu-XRYr2FN~T6nMnt9mgO3Cn5FAdLVIDqrwloKIL8o4dnWtfZZSd2rk3-uU54wKKpTH8N5op-4a9CxP6MQlWDIY7FJhqJhQB1qa~XtNyILC~ShrLGbmHirwiuKWFTy4WGft3GgQfrcGygGcye5LK69Qy5sO4sO1By7eTXQTOHeumBcShvST1xmjp3D~8OpaLVBcb15xTQT1YSt8KivI-qb3sLrd-O~y1-~iYwIG2fX~sGS0kZKq8OgRDp4FHpXzoI5o54lc6GPGXj-mU95EcpGhVeUUnFpPwM4zPnvJlSCUT~gkUiPKKPMrspXkhopZ8jOw__",
    title:
      "Nifty is taking support from the trending and the next resistance zone will be around 25130.",
  },
];

