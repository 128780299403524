import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Background from '../Assets/Background.svg';
import Tracker from '../Assets/Tracker.svg';
import Help from '../Assets/Help.svg';
import LoginBg from '../Assets/LoginBg.svg';
import Email from '../Assets/Email.svg';
import axios from 'axios';
import { useStateContext } from '../Contexts/ContextProvider';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from "./firebaseConfig"
import { FiEye, FiEyeOff } from 'react-icons/fi';


const Login = ({ onLogin }) => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const endPoint = 'https://webai-forex-backend-ashy.vercel.app'
    const {
        setIsAuthenticated
    } = useStateContext();

    const backgroundStyle = {
        height: '100vh',
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (email && password) {
            try {
                await signInWithEmailAndPassword(auth, email, password)
                const response = await axios.post(`${endPoint}/api/auth/login`, {
                    email: email,
                    password: password
                });
                // Check if response was successful
                if (response.status === 200) {
                    const data = response.data;
                    console.log('Login data: ', data)
                    localStorage.setItem('user', JSON.stringify(data));
                    setIsAuthenticated(true)
                    toast.success('Login successful!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: "light",
                        transition: Bounce,
                    });
                    navigate('/membersChat');
                } else {
                    toast.error('Login failed, please check your credentials', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: "light",
                        transition: Bounce,
                    });
                }
            } catch (error) {
                console.error(error);
                toast.error(error.response?.data?.message || 'Login failed, please try again', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                    transition: Bounce,
                });
            }
        } else {
            toast.warn('Please fill out both fields.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
                transition: Bounce,
            });
        }
    };

    return (
        <div style={backgroundStyle}>
            <ToastContainer />
            <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                <div className="relative w-[90%] max-w-[900px] h-[90%] max-h-[555px] bg-[#FAFBFC] rounded-lg px-8 py-8 shadow-2xl" onClick={(e) => e.stopPropagation()}>
                    <div className="flex mt-2 mb-[15px] md:mb-0 md:mt-4 justify-between">
                        {/* <h1 className="text-[24px] md:text-[28px] font-custom leading-[28px] font-bold text-[#190041]">Forexyy</h1> */}
                        <img src="mainlogos.svg" alt="" />
                        <div>
                            <button onClick={() => navigate('/signup')} type="button" className="text-white bg-[#3EBA59] hover:bg-[#35a94f] focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-3 py-2.5 text-center shadow-lg">
                                SIGN UP
                            </button>
                        </div>
                    </div>

                    <div className="absolute bottom-0 left-0 -mr-[1px] -mb-[3.89px]">
                        <img src={LoginBg} alt="Background" className="hidden md:block" />
                    </div>

                    <div className="w-full md:w-[384px] h-auto md:h-[400.6px] relative px-5 rounded-lg bg-white shadow-lg mx-auto py-10">
                        <h2 className="text-[#292D34] font-custom text-[20px] md:text-[25.6px] font-bold leading-[36.8px] text-center mb-1 sm:mb-4">
                            Welcome back!
                        </h2>

                        <div className="max-w-md text-start mx-auto p-2">
                            <div className="mb-6">
                                <div className="mb-6">
                                    <div className="relative">
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                                            <img src={Email} alt="Trader ID" className="w-6 h-6" />
                                        </span>
                                        <input
                                            className="shadow text-[14px] appearance-none border rounded w-full py-3 px-3 pl-10 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="email"
                                            type="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            placeholder="Enter email"
                                        />
                                    </div>
                                </div>
                                <div className="relative">
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                                        <img src={Tracker} alt="Trader ID" className="w-6 h-6" />
                                    </span>
                                    <input
                                        className="shadow text-[14px] appearance-none border rounded w-full py-3 px-3 pl-10 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id="password"
                                        type={showPassword ? "text" : "password"}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        placeholder="Enter your password"
                                    />
                                    <span
                                        className="absolute inset-y-0 right-3 flex items-center cursor-pointer text-gray-500"
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? <FiEye size={18} /> : <FiEyeOff size={18} />}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <button className="w-full p-4 text-[14px] bg-[#3EBA59] text-white rounded-lg hover:bg-green-500 mb-4" onClick={handleSubmit}>
                            Log In
                        </button>

                        <button className="absolute -bottom-[67px] md:-bottom-0 -right-8 md:-right-16">
                            <img src={Help} alt="Help" />
                        </button>
                    </div>

                    <div className="font-custom absolute bottom-6 left-1/2 transform -translate-x-1/2 text-[17px] font-normal md:text-white text-gray-600 text-opacity-80 leading-[14px] text-center w-[300px]">
                        Don't have an account?{' '}
                        <span onClick={() => navigate('/signup')} className="underline cursor-pointer">
                            Sign Up
                        </span>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Login;
